import React, { useState, useEffect, useCallback } from 'react';
import { Wrapper } from '../../utils/components';
import FastQuoteTabLabel from './FastQuoteTabLabel';
import FastQuoteTabContent from './FastQuoteTabContent';
import FastQuoteCar from './FastQuoteCar';
import FastQuoteMotorbike from './FastQuoteMotorbike';
import FastQuoteHome from './FastQuoteHome';
import FastQuoteHealth from './FastQuoteHealth';
import Tabs from '../../molecules/Tabs';
import Title from '../../molecules/title/title';
import { throttle } from 'lodash';
import withPreview from '../../utils/with-preview';
import classNames from 'classnames';
import './FastQuote.scss';
import { getCommonOrganismData } from '../../utils/functions';
import AnchorHooking from '../../molecules/anchor-hooking/AnchorHooking';
import { useWithMobileResizeListener } from '../../utils/hooks/useWithMobileResizeListener';

function FastQuote({ data, considerMenuSpace }) {
  const alignTabsLeft = data?.alignTabsLeft?.value;
  const isMobileLabelVisible = data?.isMobileLabelVisible?.value;
  const scrollTab = data?.scrollTab?.value;
  const hideTabs = data?.hideTabs?.value;
  const [originalPosition, setOriginalPosition] = useState(0);
  const [fastQuoteHeight, setFastQuoteHeight] = useState(240);
  const anchor = data?.anchor?.value;
  const [showContent, setShowContent] = useState(true);
  const isMobile = useWithMobileResizeListener(992);
  const fastQuoteFixedText =
    data?.textFastQuoteFixed?.value ||
    'In pochi secondi fai la tua quotazione veloce';

  const {
    htmlTagWrapper,
    backgroundColor,
    anchorLabel,
    marginTop,
    title,
    titleHeadingTag,
    titleIconId,
    titleIconThickness,
  } = getCommonOrganismData(data);

  const type = {
    T1: 'LiferaySubmoduleFastQuoteCar',
    T2: 'LiferaySubmoduleFastQuoteMotorbike',
    T3: 'LiferaySubmoduleFastQuoteHome',
    T4: 'LiferaySubmoduleFastQuoteHealth',
  };

  const scrollFunction = useCallback(
    function scrollFunction() {
      const fastQuote = document.getElementsByClassName('fast-quote')[0];
      if (originalPosition === 0) {
        setOriginalPosition(
          Math.round(fastQuote?.getBoundingClientRect()?.top)
        );
      }
      if (fastQuote !== undefined) {
        if (!fastQuote.classList.contains('fast-quote--fixed')) {
          if (fastQuote.getBoundingClientRect().top < 135) {
            if (!fastQuote.classList.contains('fast-quote--fixed')) {
              fastQuote.classList.add('fast-quote--fixed');
              if (!hideTabs) {
                setShowContent(false);
                let tabLabels = document.querySelectorAll(
                  '[data-tablabel-active="true"]'
                );
                Array.prototype.forEach.call(tabLabels, function (tab) {
                  tab.setAttribute('data-tablabel-active', 'false');
                  tab.setAttribute('aria-selected', 'false');
                });
              }
            }
          }
        } else {
          if (window?.pageYOffset + 50 <= originalPosition) {
            fastQuote.classList.remove('fast-quote--fixed');
            if (!hideTabs) {
              setTimeout(setShowContent(true), 500);
              let tabLabels = document.querySelectorAll(
                '[data-tablabel-active="false"]'
              );
              Array.prototype.forEach.call(tabLabels, function (tab) {
                tab.setAttribute('data-tablabel-active', 'true');
                tab.setAttribute('aria-selected', 'true');
              });
            }
          }
        }
      }
    },
    [hideTabs, originalPosition]
  );

  useEffect(() => {
    window.onscroll = throttle(() => {
      if (anchor && !isMobile) {
        scrollFunction();
      }
    }, 100);
  }, [anchor, isMobile, scrollFunction]);

  const curTabs = data.tabs?.map((el) => {
    let fqType = null;
    let fqForm = null;

    switch (el.value.__typename) {
      case type.T1:
        fqType = 'car';
        fqForm = <FastQuoteCar data={el.value} />;
        break;
      case type.T2:
        fqType = 'moto';
        fqForm = <FastQuoteMotorbike data={el.value} />;
        break;
      case type.T3:
        fqType = 'home';
        fqForm = <FastQuoteHome data={el.value} />;
        break;
      case type.T4:
        fqType = 'health';
        fqForm = <FastQuoteHealth data={el.value} />;
        break;
      default:
        break;
    }

    return fqType === null
      ? null
      : {
          label: (
            <FastQuoteTabLabel
              type={fqType}
              labelIconKey={el?.value?.iconSvgId?.value}
              labelIconThickness={
                el?.value?.iconSvgId?.content?.iconThickness?.value[0]
              }
              labelString={el?.value?.label?.value}
              isMobileLabelVisible={isMobileLabelVisible}
            />
          ),
          content: <FastQuoteTabContent type={fqType} formComponent={fqForm} />,
        };
  });

  return (
    <Wrapper
      tag={htmlTagWrapper}
      data-module-code='C-43'
      data-module-name='fast-quote'
      className={classNames(`fast-quote ${marginTop}`, {
        'fast-quote--colored-background': backgroundColor !== 'transparent',
        'fast-quote--sticky': anchor,
      })}
      style={{
        '--bg': `var(--${backgroundColor})`,
        'min-height': `${fastQuoteHeight}px`,
      }}
    >
      <AnchorHooking id={anchorLabel} belowMenu={considerMenuSpace} />

      {(title || titleIconId) && (
        <Title
          tag={titleHeadingTag}
          titleIconId={titleIconId}
          titleIconThickness={titleIconThickness}
        >
          {title}
        </Title>
      )}
      <div className='fast-quote-item fast-quote-tabs__background wrapper wrapper--overflow'>
        <Tabs
          tabs={curTabs}
          alignTabsLeft={alignTabsLeft}
          scrollTab={scrollTab}
          disableTabs={hideTabs}
          className='fast-quote-tabs wrapper'
          showContentTab={showContent}
          setShowContent={setShowContent}
          hiddenText={fastQuoteFixedText}
          setFastQuoteHeight={setFastQuoteHeight}
        />
      </div>
    </Wrapper>
  );
}

export default withPreview(FastQuote);
