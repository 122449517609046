import React from 'react';
import useGMapsScript from '../../utils/hooks/useGMapsScript';
import PlacesAutocomplete from './PlacesAutocomplete';

const FastQuoteHomeAddress = (props) => {
  const gmapsLoaded = useGMapsScript();

  return (
    <>
      {gmapsLoaded && (
        <PlacesAutocomplete
          changePostalCode={props.changePostalCode}
          changeStreet={props.changeStreet}
          changeStreetNumber={props.changeStreetNumber}
          changeMunicipality={props.changeMunicipality}
          changeProvince={props.changeProvince}
          placeHolder={props.placeHolder}
          control={props.control}
          errors={props.errors}
        />
      )}
    </>
  );
};

export default FastQuoteHomeAddress;
