import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  // getLatLng,
} from 'use-places-autocomplete';
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from '@reach/combobox';

import './styles.css';
import { useController } from 'react-hook-form';
import classNames from 'classnames';

const PlacesAutocomplete = (props) => {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });

  const { field } = useController({
    name: 'address',
    control: props.control,
    // formState: { props.errors },
    rules: {
      required: 'Campo obbligatorio',
    },
  });

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);

    field.onChange(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        var place = results[0];
        var location = null;

        // Two possible use cases:
        if (place['geometry'] && place['geometry']['location']) {
          // 1 - Autocomplete entry selected explicitly
          location = place['geometry']['location'];
        } else {
          // 2 - No entry selected but "Enter" key pressed
          location = place['name'];
        }
        reverseGeocoding(location);
        // console.log(results[0]);
      });
      // .then(({ lat, lng }) => {
      //   console.log('📍 Coordinates: ', { lat, lng });
      // })
      // .catch((error) => {
      //   console.log('😱 Error: ', error);
      // });
    };

  function reverseGeocoding(location) {
    let latlng = { lat: location.lat(), lng: location.lng() };
    const google = window.google;

    let geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: latlng }, function (results, status) {
      if (status === 'OK') {
        let streetNumber = '';
        let municipality = '';
        let postalCode = '';
        let province = '';
        let street = '';

        if (results[0]) {
          let addressComponents = results[0]['address_components'];
          for (var i = 0; i < addressComponents.length; i++) {
            let addrComponentTypes = addressComponents[i]['types'];
            for (let k = 0; k < addrComponentTypes.length; k++) {
              let curType = addrComponentTypes[k];
              if (curType === 'street_number') {
                streetNumber = addressComponents[i]['long_name'];
              }
              if (curType === 'administrative_area_level_3') {
                municipality = addressComponents[i]['long_name'];
              }
              if (curType === 'postal_code') {
                postalCode = addressComponents[i]['long_name'];
              }
              if (curType === 'administrative_area_level_2') {
                province = addressComponents[i]['short_name'];
              }
              if (curType === 'route') {
                street = addressComponents[i]['short_name'];
              }
            }
          }

          props.changeStreetNumber(streetNumber);
          props.changeStreet(street);
          props.changeMunicipality(municipality);
          props.changePostalCode(postalCode);
          props.changeProvince(province);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        // <li
        //   role='option'
        //   aria-selected='false'
        //   tabindex='-1'
        //   key={place_id}
        //   onClick={handleSelect(suggestion)}
        // >
        //   <strong>{main_text}</strong> <small>{secondary_text}</small>
        // </li>
        <ComboboxOption
          key={place_id}
          value={`${main_text}, ${secondary_text}`}
          onClick={handleSelect(suggestion)}
          // onSelect={handleSelect(suggestion)}
        >
          {main_text}, {secondary_text}
        </ComboboxOption>
      );
    });

  return (
    <Combobox onSelect={handleSelect} aria-labelledby='Address'>
      <ComboboxInput
        value={value}
        onChange={handleInput}
        disabled={!ready}
        autocomplete={false}
        className={classNames('fast-quote-form__input', {
          'fast-quote-form__input--invalid': props.errors?.message,
        })}
        placeholder={props.placeHolder}
        autoComplete='new-address'
      />
      {/* We can use the "status" to decide whether we should display the dropdown or not */}
      <ComboboxPopover>
        <ComboboxList persistSelection>
          {status === 'OK' && renderSuggestions()}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

export default PlacesAutocomplete;
