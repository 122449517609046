import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import FastQuoteHomeAddress from './FastQuoteHomeAddress';
import withPreview from '../../utils/with-preview';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import classNames from 'classnames';
import Select from 'react-select';

function FastQuoteHome({ data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };

  const [mq, setMq] = useState('');
  const [homeType, setHomeType] = useState('1');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [homeProprietary, setHomeProprietary] = useState('1');
  const [province, setProvince] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [street, setStreet] = useState('');

  const mqErrorMessage = 'Inserire un valore numerico';
  const dateOfBirthErrrorMessage = 'Inserisci una data in formato GG/MM/AAAA';

  const [privacyValue, setPrivacyValue] = useState(
    !privacyInfo?.showRadioPrivacy
  );

  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;

  const homeTypeOptions = data.homeTypePlaceholder?.content.homeType.map(
    (item, i) => {
      return {
        label: item.value,
        value: i + 1,
      };
    }
  );

  const personTypeOptions =
    data.homeProprietaryPlaceholder?.content.homeProprietary.map((item, i) => {
      return {
        label: item.value,
        value: i + 1,
      };
    });

  function encodeB2c(
    tipologiaAbitazione,
    relazioneAssicurato,
    mq,
    provincia,
    comune,
    cap,
    numeroCivico,
    indirizzo,
    dataNascita
  ) {
    var params =
      't=' +
      tipologiaAbitazione +
      '&' +
      'r=' +
      relazioneAssicurato +
      '&' +
      'm=' +
      mq +
      '&' +
      'p=' +
      provincia +
      '&' +
      'c=' +
      comune +
      '&' +
      'cp=' +
      cap +
      '&' +
      'n=' +
      numeroCivico +
      '&' +
      'a=' +
      indirizzo +
      '&' +
      'd=' +
      dataNascita +
      '&' +
      'o=1';

    return btoa(params);
  }

  const onSubmit = (data) => {
    let encoded = encodeB2c(
      homeType,
      homeProprietary,
      mq,
      province,
      municipality,
      postalCode,
      streetNumber,
      street,
      dateOfBirth
    );

    let url = 'https://quotti.cattolica.it/#/b2cPortaleCasa/' + encoded;
    navigate(url);
  };

  const handleChangeMq = (e) => {
    setMq(e.target.value);
  };

  const handleChangeHomeType = (e) => {
    setHomeType(e.value);
  };

  const handleChangeHomeProprietary = (e) => {
    setHomeProprietary(e.value);
  };

  const handleChangeProvince = (code) => {
    setProvince(code);
  };

  const handleChangeMunicipality = (code) => {
    setMunicipality(code);
  };

  const handleChangePostalCode = (code) => {
    setPostalCode(code);
  };

  const handleChangeStreetNumber = (code) => {
    setStreetNumber(code);
  };

  const handleChangeStreet = (code) => {
    setStreet(code);
  };

  const handleChangeDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleChangePrivacyRadio = (e) => {
    setPrivacyValue(e.target.value);
  };

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--home'
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className='fast-quote-group__wrapper fast-quote-group--home'>
          <fieldset className='fast-quote-group fast-quote-group__home fast-quote-group__type'>
            <label className='fast-quote-form__label' htmlFor='mq'>
              {data.homeTypePlaceholder?.value}
            </label>

            {typeof document !== 'undefined' && (
              <Select
                options={homeTypeOptions}
                classNamePrefix='select'
                className='fast-quote-form__select fast-quote-form__select--hometype'
                isSearchable={false}
                defaultValue={{
                  label: homeTypeOptions?.[0].label,
                  value: homeTypeOptions?.[0].value,
                }}
                onChange={handleChangeHomeType}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
              />
            )}
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__home fast-quote-group__mq'>
            <label className='fast-quote-form__label' htmlFor='mq'>
              {data.homeMq?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid': errors['mq']?.message,
              })}
              id='mq'
              type='text'
              name='mq'
              placeholder={data.homeMq?.value}
              value={mq}
              {...register('mq', {
                required: 'Campo obbligatorio',
                onChange: handleChangeMq,
                pattern: {
                  value: /[0-9]/,
                  message: mqErrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['mq'] && (errors['mq'] ? errors['mq'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__home  fast-quote-group__prop'>
            <label className='fast-quote-form__label' htmlFor='mq'>
              {data.homeProprietaryPlaceholder?.value}
            </label>

            {typeof document !== 'undefined' && (
              <Select
                options={personTypeOptions}
                classNamePrefix='select'
                className='fast-quote-form__select fast-quote-form__select--persontype'
                isSearchable={false}
                defaultValue={{
                  label: personTypeOptions?.[0].label,
                  value: personTypeOptions?.[0].value,
                }}
                onChange={handleChangeHomeProprietary}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
              />
            )}
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__address'>
            <label className='fast-quote-form__label' htmlFor='addressHome'>
              {data?.address?.value}
            </label>
            <FastQuoteHomeAddress
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid':
                  errors['streetNumber']?.message,
              })}
              id='addressHome'
              changePostalCode={handleChangePostalCode}
              changeStreet={handleChangeStreet}
              changeStreetNumber={handleChangeStreetNumber}
              changeMunicipality={handleChangeMunicipality}
              changeProvince={handleChangeProvince}
              placeHolder={data?.address?.value}
              control={control}
              errors={errors['address']}
            />
            <input
              id='streetNumber'
              type='text'
              hidden
              value={streetNumber}
              {...register('streetNumber', {
                onChange: handleChangeStreetNumber,
              })}
            ></input>
            <input
              id='streetHealth'
              type='text'
              hidden
              value={street}
              {...register('street', {
                onChange: handleChangeStreet,
              })}
            ></input>
            <input
              id='municipalityHealth'
              type='text'
              hidden
              value={municipality}
              {...register('municipality', {
                onChange: handleChangeMunicipality,
              })}
            ></input>
            <input
              id='postalCodeHealth'
              type='text'
              hidden
              value={postalCode}
              {...register('postalCode', {
                onChange: handleChangePostalCode,
              })}
            ></input>
            <input
              id='provinceHealth'
              type='text'
              hidden
              value={province}
              {...register('province', {
                onChange: handleChangeProvince,
              })}
            ></input>

            <span className='fast-quote-form__helper-text'>
              {errors['address'] &&
                (errors['address'] ? errors['address'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__birth'>
            <label className='fast-quote-form__label' htmlFor='dateOfBirthHome'>
              {data?.birth?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid':
                  errors['Data di nascita']?.message,
              })}
              type='datetime'
              name='dateOfBirthHome'
              id='dateOfBirthHome'
              placeholder={data?.birth?.content?.birthFocus?.value}
              value={dateOfBirth}
              {...register('Data di nascita', {
                required: 'Campo obbligatorio',
                onChange: handleChangeDateOfBirth,
                pattern: {
                  value:
                    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
                  message: dateOfBirthErrrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['Data di nascita'] &&
                (errors['Data di nascita']
                  ? errors['Data di nascita'].message
                  : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-info fast-quote-group__privacy'>
            <div className='fast-quote-info__icon'>
              <AlertCircle />
            </div>

            <div>
              {privacyInfo.introText}
              {privacyInfo.doc && (
                <a
                  className='fast-quote-info__link'
                  href={privacyInfo.docURL}
                  title={privacyInfo.title}
                >
                  {privacyInfo.title}
                </a>
              )}
              {privacyInfo.showRadioPrivacy && (
                <fieldset>
                  {privacyInfo.btnValue && (
                    <legend>Cliccando su {privacyInfo.btnValue}</legend>
                  )}
                  <div className='fast-quote-info__radio'>
                    <input
                      type='radio'
                      name='privacyValue'
                      value={true}
                      id='privacyValueOK'
                      {...register('privacy', {
                        required: 'Campo obbligatorio',
                        onChange: handleChangePrivacyRadio,
                      })}
                    />
                    <label htmlFor='privacyValueOK'>Sono d&apos;accordo</label>

                    <input
                      type='radio'
                      name='privacyValue'
                      value={false}
                      id='privacyValueKO'
                      {...register('privacy', {
                        required: 'Campo obbligatorio',
                        onChange: handleChangePrivacyRadio,
                      })}
                    />
                    <label htmlFor='privacyValueKO'>
                      Non sono d&apos;accordo
                    </label>
                  </div>
                </fieldset>
              )}

              {privacyInfo.outroText}
            </div>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__submit'>
            <label
              className='fast-quote-form__label fast-quote-form__label--submit'
              htmlFor='submitHome'
            >
              {data?.btnLabel?.value}
            </label>
            <input
              className='fast-quote-form__input'
              type='submit'
              id='submitHome'
              value={data?.btnLabel?.value}
              {...register('Submit', {
                // disabled: !(
                //   !!dateOfBirth &&
                //   !!mq &&
                //   !!privacyValue &&
                //   !!address
                // ),
                disabled: !(isValid && privacyValue),
              })}
            />
          </fieldset>
        </fieldset>
      </form>
      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuoteHome);
