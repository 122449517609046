import { useState, useEffect } from 'react';

const ISMAPSLOADED = Symbol('ISMAPSLOADED');

const useGMapsScript = () => {
  const [gmapsLoaded, setGmapsLoaded] = useState(false);

  useEffect(() => {
    if (document.getElementById('google-maps-script') === null) {
      let mapsLoaded = false;
      window[ISMAPSLOADED] = new Promise((resolve) => {
        if (mapsLoaded) {
          resolve();
        } else {
          window.initMap = () => {
            mapsLoaded = true;
            resolve();
          };
        }
      });

      const gmapScriptEl = document.createElement(`script`);
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?client=gme-cattolicaservices1&libraries=places&callback=initMap`;
      gmapScriptEl.id = 'google-maps-script';
      document
        .querySelector(`body`)
        .insertAdjacentElement(`beforeend`, gmapScriptEl);
    }
    window[ISMAPSLOADED].then(() => setGmapsLoaded(true));
  }, []);

  return gmapsLoaded;
};

export default useGMapsScript;
