import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';
import FastQuoteHomeAddress from './FastQuoteHomeAddress';
import AlertCircle from '../../atoms/static-icons/alert-circle';
import withPreview from '../../utils/with-preview';
import Select from 'react-select';
import classNames from 'classnames';

function FastQuoteHealth({ data }) {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'all',
  });

  const privacyInfo = {
    introText: data?.privacy?.content?.privacyIntroText?.value,
    doc: data?.privacy?.content?.privacyDoc,
    docURL: data?.privacy?.content?.privacyDoc?.value?.contentUrl,
    title: data?.privacy?.value,
    outroText: data?.privacy?.content?.privacyOutroText?.value,
    showRadioPrivacy: data?.privacy?.content?.showRadioPrivacy?.value,
    btnValue: data?.btnLabel?.value,
  };

  const promoValue = data?.promoValue?.value;
  const promoText = data?.promoValue?.content?.promoText?.value;

  const [weight, setWeight] = useState('');
  const [height, setHeight] = useState('');
  const [email, setEmail] = useState('');
  const [jobType, setJobType] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [province, setProvince] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [streetNumber, setStreetNumber] = useState('');
  const [street, setStreet] = useState('');
  const [privacyValue, setPrivacyValue] = useState(
    !privacyInfo?.showRadioPrivacy
  );
  const [elencoProfessioni, setElencoProfessioni] = useState([]);

  const weightErrorMessage = 'Inserire un valore numerico';
  const heightErrorMessage = 'Inserire un valore numerico';
  const dateOfBirthErrrorMessage = 'Inserisci una data in formato GG/MM/AAAA';
  const emailErrorMessage = 'Inserisci un formato di email valido.';

  function encodeB2c(
    email,
    weight,
    height,
    jobType,
    privacyValue,
    province,
    municipality,
    postalCode,
    streetNumber,
    street,
    dateOfBirth
  ) {
    let payload = {
      e: email,
      p: weight,
      a: height,
      i: jobType,
      c: privacyValue,
      ap: province,
      ac: municipality,
      acp: postalCode,
      an: streetNumber,
      ad: street,
      d: dateOfBirth,
    };

    return btoa(JSON.stringify(payload));
  }

  const onSubmit = (data) => {
    let item = document.querySelector('[name="jobType"]');
    setJobType(item.value);

    let encoded = encodeB2c(
      email,
      weight,
      height,
      jobType,
      privacyValue,
      province,
      municipality,
      postalCode,
      streetNumber,
      street,
      dateOfBirth
    );

    let url = 'https://quotti.cattolica.it/salute/#/external/' + encoded;
    navigate(url);
  };

  const handleChangeWeight = (e) => {
    setWeight(e.target.value);
  };

  const handleChangeHeight = (e) => {
    setHeight(e.target.value);
  };

  const handleChangeJobType = (e) => {
    setJobType(e.codice);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeProvince = (code) => {
    setProvince(code);
  };

  const handleChangeMunicipality = (code) => {
    setMunicipality(code);
  };

  const handleChangePostalCode = (code) => {
    setPostalCode(code);
  };

  const handleChangeStreetNumber = (code) => {
    setStreetNumber(code);
  };

  const handleChangeStreet = (code) => {
    setStreet(code);
  };

  const handleChangeDateOfBirth = (e) => {
    setDateOfBirth(e.target.value);
  };

  const handleChangePrivacyRadio = (e) => {
    setPrivacyValue(e.target.value);
  };

  useEffect(() => {
    const protectedUrl =
      'https://a6rj8koub3.execute-api.eu-west-1.amazonaws.com/customer-api/insurables/v1/quotations/retrieve/elencoProfessioni';

    fetch(protectedUrl, {
      headers: {
        secret: 'bH3cmZ9w6euaFkmHtp9cTSxBDdkSTd5m',
      },
    })
      .then((r) => {
        if (r.ok) {
          return r.json();
        } else {
          throw new Error('Something went wrong - Error in FastQuoteHealth');
        }
      })
      .then((json) => {
        setElencoProfessioni(json?.elencoProfessioni);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className='fast-quote-container'>
      <form
        className='fast-quote-form fast-quote-form--health'
        onSubmit={handleSubmit(onSubmit)}
      >
        <fieldset className='fast-quote-group__wrapper fast-quote-group--health'>
          <fieldset className='fast-quote-group fast-quote-group__health fast-quote-group__weight'>
            <label className='fast-quote-form__label' htmlFor='weight'>
              {data.weightPlaceholder?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid': errors['weight']?.message,
              })}
              id='weight'
              type='text'
              name='weight'
              placeholder={data.weightPlaceholder?.value}
              value={weight}
              {...register('weight', {
                required: 'Campo obbligatorio',
                onChange: handleChangeWeight,
                pattern: {
                  value: /[0-9]/,
                  message: weightErrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['weight'] &&
                (errors['weight'] ? errors['weight'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__health fast-quote-group__height'>
            <label className='fast-quote-form__label' htmlFor='height'>
              {data.heightPlaceholder?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid': errors['height']?.message,
              })}
              id='height'
              type='text'
              name='height'
              placeholder={data.heightPlaceholder?.value}
              value={height}
              {...register('height', {
                required: 'Campo obbligatorio',
                onChange: handleChangeHeight,
                pattern: {
                  value: /[0-9]/,
                  message: heightErrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['height'] &&
                (errors['height'] ? errors['height'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__jobType'>
            <label className='fast-quote-form__label' htmlFor='jobType'>
              {data.jobTypePlaceholder?.value}
            </label>

            {typeof document !== 'undefined' && (
              <Select
                options={elencoProfessioni}
                getOptionLabel={(option) => option.descrizione}
                getOptionValue={(option) => option.codice}
                classNamePrefix='select'
                className='fast-quote-form__select fast-quote-form__select--jobtype'
                id='job'
                name='jobType'
                isSearchable={false}
                placeholder={data.jobTypePlaceholder?.value}
                onChange={handleChangeJobType}
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 10 }) }}
              />
            )}
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__address'>
            <label className='fast-quote-form__label' htmlFor='addressHealth'>
              {data?.address?.value}
            </label>
            <FastQuoteHomeAddress
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid':
                  errors['streetNumber']?.message,
              })}
              id='addressHealth'
              changePostalCode={handleChangePostalCode}
              changeStreet={handleChangeStreet}
              changeStreetNumber={handleChangeStreetNumber}
              changeMunicipality={handleChangeMunicipality}
              changeProvince={handleChangeProvince}
              placeHolder={data?.address?.value}
              control={control}
              errors={errors['address']}
            />
            <input
              id='streetNumber'
              type='text'
              hidden
              value={streetNumber}
              {...register('streetNumber', {
                onChange: handleChangeStreetNumber,
              })}
            ></input>
            <input
              id='streetHealth'
              type='text'
              hidden
              value={street}
              {...register('street', {
                onChange: handleChangeStreet,
              })}
            ></input>
            <input
              id='municipalityHealth'
              type='text'
              hidden
              value={municipality}
              {...register('municipality', {
                onChange: handleChangeMunicipality,
              })}
            ></input>
            <input
              id='postalCodeHealth'
              type='text'
              hidden
              value={postalCode}
              {...register('postalCode', {
                onChange: handleChangePostalCode,
              })}
            ></input>
            <input
              id='provinceHealth'
              type='text'
              hidden
              value={province}
              {...register('province', {
                onChange: handleChangeProvince,
              })}
            ></input>

            <span className='fast-quote-form__helper-text'>
              {errors['address'] &&
                (errors['address'] ? errors['address'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__birth'>
            <label
              className='fast-quote-form__label'
              htmlFor='dateOfBirthHealth'
            >
              {data?.birth?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid':
                  errors['Data di nascita']?.message,
              })}
              type='datetime'
              name='dateOfBirthHealth'
              id='dateOfBirthHealth'
              placeholder={data?.birth?.content?.birthFocus?.value}
              value={dateOfBirth}
              {...register('Data di nascita', {
                required: 'Campo obbligatorio',
                onChange: handleChangeDateOfBirth,
                pattern: {
                  value:
                    /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i,
                  message: dateOfBirthErrrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['Data di nascita'] &&
                (errors['Data di nascita']
                  ? errors['Data di nascita'].message
                  : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__email'>
            <label className='fast-quote-form__label' htmlFor='emailHealth'>
              {data.emailPlaceholder?.value}
            </label>
            <input
              className={classNames('fast-quote-form__input', {
                'fast-quote-form__input--invalid': errors['email']?.message,
              })}
              type='text'
              id='emailHealth'
              placeholder={data.emailPlaceholder?.value}
              value={email}
              {...register('email', {
                onChange: handleChangeEmail,
                pattern: {
                  value: /^\S+@\S+$/i,
                  message: emailErrorMessage,
                },
              })}
            />
            <span className='fast-quote-form__helper-text'>
              {errors['email'] &&
                (errors['email'] ? errors['email'].message : '')}
            </span>
          </fieldset>

          <fieldset className='fast-quote-info fast-quote-group__privacy'>
            <div className='fast-quote-info__icon'>
              <AlertCircle />
            </div>

            <div>
              {privacyInfo.introText}
              {privacyInfo.doc && (
                <a
                  className='fast-quote-info__link'
                  href={privacyInfo.docURL}
                  title={privacyInfo.title}
                >
                  {privacyInfo.title}
                </a>
              )}
              {privacyInfo.showRadioPrivacy && (
                <fieldset>
                  {privacyInfo.btnValue && (
                    <legend>Cliccando su {privacyInfo.btnValue}</legend>
                  )}
                  <div className='fast-quote-info__radio'>
                    <input
                      type='radio'
                      name='privacyValue'
                      value={true}
                      id='privacyValueOK'
                      {...register('privacy', {
                        required: 'Campo obbligatorio',
                        onChange: handleChangePrivacyRadio,
                      })}
                    />
                    <label htmlFor='privacyValueOK'>Sono d&apos;accordo</label>

                    <input
                      type='radio'
                      name='privacyValue'
                      value={false}
                      id='privacyValueKO'
                      {...register('privacy', {
                        required: 'Campo obbligatorio',
                        onChange: handleChangePrivacyRadio,
                      })}
                    />
                    <label htmlFor='privacyValueKO'>
                      Non sono d&apos;accordo
                    </label>
                  </div>
                </fieldset>
              )}

              {privacyInfo.outroText}
            </div>
          </fieldset>

          <fieldset className='fast-quote-group fast-quote-group__submit'>
            <label
              className='fast-quote-form__label fast-quote-form__label--submit'
              htmlFor='submitHealth'
            >
              {data?.btnLabel?.value}
            </label>
            <input
              className='fast-quote-form__input'
              type='submit'
              id='submitHealth'
              value={data?.btnLabel?.value}
              {...register('Submit', {
                // disabled: !(
                //   !!dateOfBirth &&
                //   !!weight &&
                //   !!height &&
                //   !!privacyValue &&
                //   !!address
                // ),
                disabled: !(isValid && privacyValue),
              })}
            />
          </fieldset>
        </fieldset>
      </form>
      {promoValue && (
        <div className='fast-quote-container__promo-box'>
          <span className='fast-quote-container__promo-box-discount'>
            {promoValue}
          </span>
          <span className='fast-quote-container__promo-box-description'>
            {promoText}
          </span>
        </div>
      )}
    </div>
  );
}

export default withPreview(FastQuoteHealth);
